import React from 'react'
import MediaMessage from './media-message'
import PollMessage from './poll-message'
import PositionMessage from './position-message'
import TextMessage from './text-message'
import EventMessage from './event-message'
import EventPreviewMessage from './event-preview-message'

function ChatMessage({ msg }) {
  const attributes = msg.attributes
  const type = attributes.type

  switch (type?.toLowerCase()) {
    case 'location': {
      return <PositionMessage msg={msg} />
    }

    case 'media': {
      return <MediaMessage msg={msg} />
    }

    case 'poll': {
      return <PollMessage msg={msg} />
    }

    case 'event': {
      return <EventMessage msg={msg} />
    }

    case 'event-preview': {
      return <EventPreviewMessage msg={msg} />
    }

    case 'text': {
      return <TextMessage msg={msg} />
    }

    default: {
      return <TextMessage msg={msg} /> // Retrocompatibility: {type: reply} and old text message without attributes, just message.body
    }
  }
}

export default ChatMessage
