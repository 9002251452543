import { Box, Button, Flex, useToast } from '@chakra-ui/react'
import ChatMessage from '@screens/chat/chat-messages'
import { SbamCoinArrow } from 'components/shared/icons/icons'
import React from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router'
import Api from 'services/api/api.service'
import { Message } from '../types'
import ChatBoxFooter from './chat-box-footer'
import ChatBoxHeader from './chat-box-header'

function ChatBox({ msg }: { msg: Message }) {
  const isMine = msg.author !== 'system'
  const type = msg.attributes?.type

  return (
    <Flex direction="column" className="chat-box" width="100%" gap="1rem" alignItems="center" justifyContent="center">
      <Flex
        backgroundColor={isMine ? 'rgba(255, 116, 54, .1)' : '#F7F7F7'}
        borderRadius="1rem"
        direction="column"
        padding="10px 10px"
        gap=".5rem"
        width="100%">
        <ChatBoxHeader msg={msg} />

        <ChatMessage msg={msg} />

        <ChatBoxFooter msg={msg} />
      </Flex>
      {type === 'event-preview' && <PublishButton msg={msg} />}
    </Flex>
  )
}

function PublishButton({ msg }: { msg: Message }) {
  const navigate = useNavigate()
  const toast = useToast()
  const limitations = msg.attributes!.event!.limitations
  const createEvent = useCreateEventMutation()

  const { data, isIdle, isLoading, isError } = useGetOrganizeCostQuery(limitations.maxParticipants)
  const organizeCost = data?.data.organizeCost

  const handleOnCreateEvent = async () => {
    try {
      const location = msg.attributes?.event?.location
      const limitations = msg.attributes?.event?.limitations

      const stringify = { ...msg.attributes?.event, location: JSON.stringify(location), limitations: JSON.stringify(limitations) }

      const { data: eventId } = await createEvent.mutateAsync(stringify)
      navigate(`/event/${eventId}`)
    } catch (e) {
      console.error(e)

      toast({
        title: "Qualcosa è andato storto durante la creazione dell'evento",
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'bottom',
      })
    }
  }

  if (isIdle && isError) {
    return <></>
  }

  return (
    <Button alignSelf="center" type="submit" variant="solid" disabled={isLoading} onClick={handleOnCreateEvent} isLoading={createEvent.isLoading}>
      <Box mr="3">Pubblica</Box>

      <Box>
        {`-${organizeCost}`}
        <SbamCoinArrow />
      </Box>
    </Button>
  )
}

const useCreateEventMutation = () => useMutation('create-event', (event?: {}) => Api.createEvent(event))
const useGetOrganizeCostQuery = (maxParticipants: number) => useQuery('organize-cost', (event?: {}) => Api.quoteEvent(maxParticipants))

export default ChatBox
