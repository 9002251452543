import { useToast } from '@chakra-ui/react'
import { JSONValue } from '@twilio/conversations'
import { AppContext } from 'app.context'
import { useContext } from 'react'
import { useMutation } from 'react-query'
import { Reply } from '../types'

export type Vote = {
  identity: string
  timestamp: number
}

export type PollOption = {
  value: string
  votes: Vote[]
  key: string
}

export type Poll = {
  question: string
  options: PollOption[]
  multipleAnswers: boolean
}

export type PollMessageAttributes = JSONValue & {
  type: 'poll'
  reply?: Reply
  poll: Poll
}

const useSendPollMessageMutation = (options = {}): any => {
  const toast = useToast()
  const { client: twilioClient } = useContext(AppContext)

  return useMutation(
    'send-poll-message',
    async ({ sid, poll, reply }: { sid: string; poll: Poll; reply?: Reply }) => {
      if (!twilioClient || !sid) {
        return
      }

      const conversation = await twilioClient.getConversationBySid(sid)
      const attributes = reply ? { type: 'poll', poll, reply } : { type: 'poll', poll }

      await conversation
        .prepareMessage()
        .setBody('')
        .setAttributes(attributes as PollMessageAttributes)
        .build()
        .send()
    },
    {
      onError(error: any) {
        console.error(error)

        toast({
          title: error?.message || 'Qualcosa è andato storto',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom',
        })
      },
      ...options,
    },
  )
}

export default useSendPollMessageMutation
