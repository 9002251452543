import { Box, Flex, InputGroup } from '@chakra-ui/react'
import { I } from 'components/shared/input/input.style'
import React, { useState } from 'react'
import { ReactComponent as CalendarIco } from 'assets/calendar-ico.svg'
import { ReactComponent as PlaneIco } from 'assets/plane-ico.svg'
import { Link } from 'react-router-dom'
import ChatPlusMenuToggle from './chat-plus-menu/chat-plus-menu-toggle'
import { useChatContext } from './context/chat-context'
import useSendTextMessageMutation from './hooks/use-send-text-message-mutation'

function ChatInputText() {
  const { setReply, reply, chatRef, inputRef } = useChatContext()
  const [message, setMessage] = useState<string>('')

  const sendMessage = useSendTextMessageMutation({
    onSuccess: () => {
      setMessage('')
      setReply(undefined)

      inputRef.current?.focus()
      inputRef.current?.click()

      if (chatRef.current) {
        chatRef.current.scrollTop = chatRef.current?.scrollHeight
      }
    },
  })

  const handleOnInput = ({ currentTarget: { value } }) => {
    setMessage(value)
  }

  const handleOnKeyDown = event => {
    if (event.key === 'Enter') {
      sendMessage.mutate({ message, reply })
    }
  }

  const handleOnSendMessage = () => {
    sendMessage.mutate({ message, reply })
  }

  return (
    <Flex alignItems="center" px="1rem" gap="1rem" my="10px" width="100%" position="absolute" left="0" bottom="0">
      <ChatPlusMenuToggle />

      <InputGroup>
        <I
          ref={inputRef}
          type="text"
          placeholder="Messaggio"
          value={message}
          onInput={handleOnInput}
          onKeyDown={handleOnKeyDown}
          isDisabled={sendMessage.isLoading}
        />
      </InputGroup>

      <Box color="brand.500" cursor="pointer" fontSize="1.5rem">
        <Link to="/event/create/wizard">
          <CalendarIco />
        </Link>
      </Box>

      <Box color="brand.500" cursor="pointer" fontSize="1.5rem" onClick={handleOnSendMessage}>
        <PlaneIco />
      </Box>
    </Flex>
  )
}

export default ChatInputText
