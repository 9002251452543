import { Flex } from '@chakra-ui/react'
import React from 'react'
import ChatMessage from '../chat-messages'
import ChatBox from './chat-box'

function Body({ messages, chatRef, isLoading }) {
  return (
    <Flex ref={chatRef} direction="column" width="100%" height="100%" pt="26px" px="1rem" gap="1.25rem" overflowY="scroll">
      {messages.map((msg, index) => (
        <ChatBox msg={msg} key={index} />
      ))}

      <LoadingMessage isLoading={isLoading} />
    </Flex>
  )
}

function LoadingMessage({ isLoading }) {
  if (isLoading) {
    return <ChatMessage msg={{ body: 'Caricamento...', attributes: { type: 'text' } }} />
  }

  return <></>
}

export default Body
