import { Box, Flex } from '@chakra-ui/react'
import ChatMessage from '@screens/chat/chat-messages'
import Wrapper from 'components/shared/wrapper/wrapper.component'
import { getGenerativeModel, getVertexAI } from 'firebase/vertexai-preview'
import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import Api from 'services/api/api.service'
import { firebaseApp } from 'services/firebase.service'
import { Category } from 'typings/database'
import { ValidationError } from 'yup'
import Body from './body'
import { selectorAuth } from '@src/features/auth/auth.slice'
import Footer from './footer'
import Header from './header'
import schema from './schema'
import { Factory, GeminiAnswer, GeminiEvent, GeminiMetadata, Message } from './types'
import useGetWelcomeMessageFromGemini from './use-get-welcome-message-from-gemini'
import useSendMessageToGeminiMutation from './use-send-message-to-gemini-mutation'
const vertexAi = getVertexAI(firebaseApp)
const model = getGenerativeModel(vertexAi, { model: 'gemini-1.5-flash' })
const chat = model.startChat()

const CreateEventChatBot = () => {
  const chatRef = useRef<HTMLInputElement>(null)
  const [messages, setMessages] = useState<Message[]>([])
  const [yupErrors, setYupErrors] = useState<string>()
  const [eventJSON, setEventJSON] = useState<GeminiEvent>()
  const [metadata, setMetadata] = useState<GeminiMetadata>()
  const [isLoadingPreview, setIsLoadingPreview] = useState<boolean>(false)
  const [prevQuestion, setPrevQuestion] = useState<string>()

  const authState = useSelector(selectorAuth)
  const firstName = authState?.user?.firstName || ''

  const { isLoading, isError } = useGetWelcomeMessageFromGemini(firstName, chat, {
    onSuccess: ({ question, event }: GeminiAnswer) => {
      setMessages([Factory.textMessage('system', question)])
      setPrevQuestion(question)
      setEventJSON(event)
    },
    onError: err => {
      console.error(err)
      setMessages([Factory.textMessage('system', 'Errore: qualcosa è andato storto')])
    },
  })

  const sendMessage = useSendMessageToGeminiMutation(chat, {
    onSuccess: ({ question, event, metadata }: GeminiAnswer) => {
      if (metadata.isAllMandatoryFieldsPopulated) {
        setIsLoadingPreview(true)
      } else {
        setMessages(rest => rest.concat(Factory.textMessage('system', question)))
      }

      setPrevQuestion(question)
      setEventJSON(event)
      setMetadata(metadata)

      schema.validate(event, { abortEarly: false }).catch(({ inner }: ValidationError) => {
        const errorMessages = inner.map(({ message }) => message).join('\n')

        setYupErrors(errorMessages)
      })
    },
    onError: err => {
      console.error(err)
      setMessages([Factory.textMessage('system', 'Errore: qualcosa è andato storto')])
    },
  })

  useScrollToTheEndWhenNewMessageArrive(chatRef, messages)
  useCreateEventPreviewMessage(setMessages, setIsLoadingPreview, eventJSON, metadata)

  if (isError) {
    return <></>
  }

  if (isLoading) {
    return (
      <Wrapper h="100%" pos="relative" px="0">
        <Header />

        <Flex
          ref={chatRef}
          className="chat-screen"
          direction="column"
          width="100%"
          height="100%"
          pt="26px"
          px="1rem"
          gap="1.25rem"
          overflowY="scroll">
          <LoadingMessage isLoading />
        </Flex>

        <Flex direction="column">
          <Box h="75px"></Box>
        </Flex>
      </Wrapper>
    )
  }

  return (
    <Wrapper h="100%" pos="relative" px="0">
      <Header />

      <Body chatRef={chatRef} messages={messages} isLoading={sendMessage.isLoading || isLoadingPreview} />

      <Footer eventJSON={eventJSON} prevQuestion={prevQuestion} yupErrors={yupErrors} sendMessage={sendMessage} setMessages={setMessages} />
    </Wrapper>
  )
}

function LoadingMessage({ isLoading }) {
  if (isLoading) {
    return <ChatMessage msg={{ body: 'Caricamento...', attributes: { type: 'text' } }} />
  }

  return <></>
}

const useScrollToTheEndWhenNewMessageArrive = (chatRef, messages) => {
  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current?.scrollHeight
    }
  }, [chatRef, messages]) // Put 'messages' as deps do the trick
}

const useCreateEventPreviewMessage = (setMessages, setIsLoadingPreview, event?: GeminiEvent, metadata?: GeminiMetadata) => {
  const isAllMandatoryFieldsPopulated = metadata?.isAllMandatoryFieldsPopulated

  const { isSuccess, data } = useGetCategoriesQuery()

  useEffect(() => {
    async function foo() {
      if (!isSuccess) {
        return
      }

      const categories = data?.data || []

      if (isAllMandatoryFieldsPopulated && event) {
        const { data: places } = await Api.places(`${event.location.city}, ${event.location.street} ${event.location.houseNumber}`)
        const placeId = places?.[0]?.placeId

        const { data: place } = await Api.place(placeId)
        const location = place?.location

        const categoryNameToCategoryId = categories.find(({ name }) => name === event.categoryId)?._id
        const genderBalanceActive =
          event.limitations.femaleParticipants || event.limitations.maleParticipants || event.limitations.otherParticipants ? true : false

        setMessages(rest => {
          return rest.concat(
            Factory.eventPrevewMessage('system', {
              ...event,
              location,
              limitations: {
                ...event.limitations,
                genderBalanceActive,
              },
              categoryId: categoryNameToCategoryId,
            }),
          )
        })

        setIsLoadingPreview(false)
      }
    }

    foo()
  }, [data?.data, event, isAllMandatoryFieldsPopulated, isSuccess, setIsLoadingPreview, setMessages])
}

const useGetCategoriesQuery = () =>
  useQuery('categories', () => Api.categories()) as {
    error: String | any
    isSuccess: boolean
    data: { data: Category[] }
  }

export default CreateEventChatBot
