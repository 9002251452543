import { Box, Text, InputGroup, InputProps, InputRightElement, Button } from '@chakra-ui/react'
import { Slide, Fade } from '@chakra-ui/transition'
import React, { Ref, useState } from 'react'

import PositionInput from './position.input'
import FileInput from './file.input'
import { I, IRE, LittleI } from './input.style'
import TextareaField from '../textarea/textarea.component'
import PhoneInput from 'react-phone-input-2'
import './phone.css'
import { ReactComponent as EuroIcon } from './../../../assets/euro.svg'
import { CalendarInput } from './calendar.input'

export interface InputComponentProps extends Omit<Omit<Omit<InputProps, 'name'>, 'value'>, 'onChange'> {
  value?: any
  onChange?: any
  [key: string]: any
}

export const Input = React.forwardRef((props: InputComponentProps, ref: any) => {
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(!show)
  if (props.type === 'number' && props.buttonstyle === 'LittleI') {
    return <LittleI focusBorderColor="#FF7436" variant="filled" ref={ref} {...props} />
  }
  if (props.type === 'file') {
    return <FileInput ref={ref} {...props} previewstring={props.previewstring} onsetcover={props.onsetcover} />
  }
  if (props.type === 'location') {
    return <PositionInput ref={ref} {...(props as { value: any; onChange: any })} />
  }
  if (props.type === 'textarea') {
    return <TextareaField ref={ref} focusBorderColor="#FF7436" _focus={{ boxShadow: '0 0 0 2px #ff7436' }} {...props} />
  }
  if (props.type === 'currency') {
    return (
      <InputGroup size="sm">
        <I ref={ref} {...props} cursor="pointer" focusBorderColor="#FF7436" variant="filled" type="number" />
        <IRE
          children={
            !props.disabled && (
              <Box borderRadius="10px" width="60px" height="100%" minH="40px" maxH="50px" backgroundColor="brand.500" pl="4" mr="-1">
                <EuroIcon color="white" />
              </Box>
            )
          }
        />
      </InputGroup>
    )
  }
  if (props.type === 'password') {
    return (
      <InputGroup size="md">
        <I focusBorderColor="#FF7436" variant="filled" ref={ref} {...props} type={show ? 'text' : 'password'} />
        <InputRightElement width="3rem" h="95%" my="auto" mr="8px">
          <Button variant="ghost" h="1.75rem" w="100%" size="sm" onClick={handleShow}>
            {show ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" opacity=".4">
                <g id="visibility_black_24dp">
                  <path id="Tracciato_19" data-name="Tracciato 19" d="M0,0H24V24H0Z" fill="none" />
                  <path
                    id="Tracciato_20"
                    data-name="Tracciato 20"
                    d="M12,6a9.77,9.77,0,0,1,8.82,5.5,9.822,9.822,0,0,1-17.64,0A9.77,9.77,0,0,1,12,6m0-2A11.827,11.827,0,0,0,1,11.5a11.817,11.817,0,0,0,22,0A11.827,11.827,0,0,0,12,4Zm0,5a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,12,9m0-2a4.5,4.5,0,1,0,4.5,4.5A4.507,4.507,0,0,0,12,7Z"
                    transform="translate(0 1)"
                    fill="#011936"
                  />
                  <line
                    id="Linea_33"
                    data-name="Linea 33"
                    y1="18"
                    x2="18"
                    transform="translate(3.5 3.5)"
                    fill="none"
                    stroke="#011936"
                    strokeWidth="2"
                  />
                </g>
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="15" viewBox="0 0 22 15" opacity=".4">
                <path
                  d="M12,6a9.77,9.77,0,0,1,8.82,5.5,9.822,9.822,0,0,1-17.64,0A9.77,9.77,0,0,1,12,6m0-2A11.827,11.827,0,0,0,1,11.5a11.817,11.817,0,0,0,22,0A11.827,11.827,0,0,0,12,4Zm0,5a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,12,9m0-2a4.5,4.5,0,1,0,4.5,4.5A4.507,4.507,0,0,0,12,7Z"
                  transform="translate(-1 -4)"
                  fill="#011936"
                />
              </svg>
            )}
          </Button>
        </InputRightElement>
      </InputGroup>
    )
  }
  if (props.type === 'tel') {
    const { preferredContries, country, ...rest } = props
    return (
      <PhoneInput
        country={country}
        preferredCountries={preferredContries}
        onChange={props.onChange}
        {...rest}
        inputProps={{
          name: 'phone',
        }}
      />
    )
  }

  if (props.type === 'datetime-local') {
    return <CalendarInput {...props} />
  }

  return <I focusBorderColor="#FF7436" variant="filled" ref={ref} {...props} />
})

const InputField = React.forwardRef(
  (
    props: Omit<InputProps, 'value'> & {
      value?: any
      onChange?: any
      [key: string]: any
      onFileSelected?: (uri: string) => void
      previewString: string
    },
    ref: Ref<any>,
  ) => {
    const showPlaceholder = true
    return (
      <Box pos="relative">
        <Slide hidden={props.hidden} direction="bottom" in={showPlaceholder} style={{ zIndex: -1, position: 'relative', pointerEvents: 'none' }}>
          <Fade in={showPlaceholder}>
            <Text pb="5px" fontWeight="semibold" color="black">
              {props.label}
              {props.required && (
                <Text as="span" color="brand.500" ml="4px" mt="-2px" fontSize="26px" position="absolute">
                  *
                </Text>
              )}
            </Text>
          </Fade>
        </Slide>
        <Input ref={ref} {...props} />
      </Box>
    )
  },
)

export default InputField
