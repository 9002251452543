export type Message = {
  author: 'system' | string
  dateCreated: Date
  body?: string
  attributes?: { type: string; event?: GeminiEvent } & {}
}

/** EVENT */
export type GeminiEvent = {
  title: string
  location: {
    city: string
    street: string
    houseNumber: number
  }
  limitations: {
    maxAge?: number
    minAge?: number
    maxParticipants: number
    maleParticipants?: number
    femaleParticipants?: number
    otherParticipants?: number
    genderBalanceActive: boolean
  }
  categoryId: string
  cost?: number
  startDate: Date
  description?: string
}

export type GeminiMetadata = {
  hasCity: boolean
  hasStreet: boolean
  hasNumber: boolean
  isAllMandatoryFieldsPopulated: boolean
}

export type GeminiAnswer = {
  question: string
  event: GeminiEvent
  metadata: GeminiMetadata
}

/** FACTORY METHODS */

export const Factory = {
  textMessage: (identity: string, body: string): Message => ({
    author: identity,
    dateCreated: new Date(),
    body,
    attributes: { type: 'text' },
  }),

  eventPrevewMessage: (identity: string, event: GeminiEvent): Message => ({
    author: identity,
    dateCreated: new Date(),
    attributes: { type: 'event-preview', event },
  }),
}
