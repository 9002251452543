import { useFormbitContext } from '@radicalbit/formbit'
import { Schema } from './schema'
import useSendPollMessageMutation from '@screens/chat/hooks/use-send-poll-mutation'
import { useChatContext } from '@screens/chat/context/chat-context'
import { useToast } from '@chakra-ui/react'
import { useCallback } from 'react'
import { useParams } from 'react-router'
import useGetCurrentCommunities from '@hooks/use-get-current-communities'

const useHandleOnSubmit = setIsModalOpen => {
  const toast = useToast()
  const { sid } = useParams()
  const { reply, setIsChatPlusMenuBoxVisible, setReply, chatRef } = useChatContext()

  const { submitForm, isDirty, form, isFormInvalid } = useFormbitContext<Schema>()
  const publishInAllTheCities = form.publishInAllTheCities

  const { data, isLoading: isLoadingCommunities } = useGetCurrentCommunities({ enabled: publishInAllTheCities })

  const sendPoll = useSendPollMessageMutation()

  const isSubmitDisabled = !isDirty || isFormInvalid() || isLoadingCommunities

  const handleOnSubmit = useCallback(() => {
    submitForm(
      async ({ form }) => {
        if (isSubmitDisabled) {
          return
        }

        const communities = data?.data || []

        try {
          const nonEmptyOptions = form.options.filter(o => !!o.value)
          const poll = { ...form, options: nonEmptyOptions }

          await sendPoll.mutate({ sid, poll, reply })

          // Those chats will receive the msg eventually
          communities
            .filter(({ chatSid }) => chatSid !== sid)
            .forEach(({ chatSid }) => {
              sendPoll.mutate({ sid: chatSid, poll })
            })

          setIsModalOpen(false)
          setIsChatPlusMenuBoxVisible(false)
          setReply(undefined)

          if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current?.scrollHeight
          }
        } catch (e) {
          toast({
            title: 'Qualcosa è andato storto',
            status: 'error',
            duration: 2500,
            isClosable: true,
            position: 'bottom',
          })
        }
      },
      () => {
        toast({
          title: 'Imposta una domanda ed almeno due opzioni (no duplicati)',
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'bottom',
        })
      },
    )
  }, [chatRef, data?.data, isSubmitDisabled, reply, sendPoll, setIsChatPlusMenuBoxVisible, setIsModalOpen, setReply, sid, submitForm, toast])

  return { handleOnSubmit, args: sendPoll, isSubmitDisabled }
}

export default useHandleOnSubmit
