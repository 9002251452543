import Logo from '@assets/sbam-logo-orange.svg'
import { Box, Flex, Spacer, useDisclosure } from '@chakra-ui/react'
import CompleteProfileModal from 'components/shared/complete-profile-modal/complete-profile-modal'
import { CreateEventIcon, SearchIcon } from 'components/shared/icons/icons'
import UserAvatar from 'components/shared/user-avatar/user-avatar'
import { selectorAuth } from 'features/auth/auth.slice'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom'
import { isAndroid, isIos } from 'utils/platform'

const Footer = () => {
  const { user } = useSelector(selectorAuth)
  const { isOpen, onOpen, onClose } = useDisclosure()

  if (!user) {
    return null
  }

  return (
    <>
      <Box height="60px" width="100%"></Box>

      <Box position="fixed" bottom="0" left="0" width="100%" height="env(safe-area-inset-bottom)" backgroundColor="#ffffff" zIndex="100"></Box>

      <Flex
        alignItems="center"
        height="65px"
        px="30px"
        position="fixed"
        bottom="0"
        left="50%"
        transform="translate(-50%)"
        width="100%"
        maxWidth="500px"
        mx="auto"
        borderTop="1px solid #F7F7F7"
        backgroundColor="#FFFFFF"
        zIndex={100}
        marginBottom="env(safe-area-inset-bottom)">
        <Flex m="auto" alignItems="center" maxWidth="500px" css={{ gap: '20px' }} width="100%">
          <Spacer />

          <Flex direction="column" justify="center" align="center">
            <Search />
          </Flex>

          <Spacer />

          <Flex direction="column" justify="center" align="center">
            <CreateEvent />
          </Flex>

          <Spacer />

          <Flex direction="column" justify="center" align="center">
            <Profile />
          </Flex>

          <Spacer />
        </Flex>
      </Flex>

      <CompleteProfileModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}

function Search() {
  const { pathname } = useLocation()

  return (
    <Link to="/">
      <SearchIcon boxSize={7} color={pathname === '/' ? '#FF7436' : '#978F90'} />
    </Link>
  )
}

function CreateEvent() {
  const navigate = useNavigate()

  const { user } = useSelector(selectorAuth)
  const { pathname } = useLocation()
  const { onOpen } = useDisclosure()

  const isBeta = process.env.REACT_APP_IS_BETA === 'true'

  const handleOnClick = async () => {
    if (isBeta) {
      if ((isIos() || isAndroid() || (user?.phoneVerified && user?.phone)) && user?.avatar) {
        navigate('/event/create/chat-bot')
      } else {
        onOpen()
      }

      return
    }

    if ((isIos() || isAndroid() || (user?.phoneVerified && user?.phone)) && user?.avatar) {
      navigate('/event/create/wizard')
    } else {
      onOpen()
    }
  }

  return (
    <Box boxSize={isBeta ? 12 : 10} cursor="pointer" onClick={handleOnClick}>
      {isBeta ? (
        <img src={Logo} alt="sbam-logo-orange" />
      ) : (
        <CreateEventIcon boxSize={10} color={pathname === '/event/create' ? '#FF7436' : '#978F90'} cursor="pointer" />
      )}
    </Box>
  )
}

function Profile() {
  const { user } = useSelector(selectorAuth)

  const { pathname } = useLocation()
  const isProfile = matchPath({ path: '/profile' }, pathname)

  return (
    <Link to="/profile">
      <UserAvatar src={user.avatar} boxSize={7} borderColor={isProfile ? '#FF7436' : '#978F90'} />
    </Link>
  )
}

export default Footer
